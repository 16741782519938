import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Link.sass';

function Link(props) {
  const {
    href, main, title, className,
  } = props;
  return (
    <div className={classNames('link', { main }, className)}>
      <a href={href}>
        {title}
      </a>
    </div>
  );
}

Link.propTypes = {
  href: PropTypes.string.isRequired,
  main: PropTypes.bool,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Link.defaultProps = {
  main: false,
  className: '',
};

export default Link;
