import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import loadable from '../../../loadable';
import Bucket from '../bucket/Bucket';

import { cartActions, couponCodeActions } from '../../actions';
import { lineItemsCountSelector, lineItemsObjectsSelector } from '../../../checkout/selectors/line_items';
import {
  orderTotalSelector, guestTokenSelector, orderNumberSelector, orderLoadingSelector,
} from '../../../checkout/selectors/order';
import { cartActiveSelector, couponCodeErrorTextSelector, couponCodeSuccessTextSelector } from '../../selectors/cart';
import styles from './Cart.module.sass';
import { currentModalSelector } from '../../../modals/selectors/modals';
import { productsActions } from '../../../product/actions';

const CartWrapper = loadable(() => import('../cart_wrapper/CartWrapper'));

class Cart extends Component {
  // used for tracking click events on window object
  bucketRef = React.createRef();

  componentDidMount() {
    const { openCart, addToBagMultiple, addToBag } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('open_cart')) {
      openCart();
    }
    if (urlParams.get('action_name') === 'add_to_bag') {
      addToBag({ id: null, variantId: urlParams.get('variant_id') });
    }
    if (urlParams.get('action_name') === 'add_to_bag_multiple') {
      addToBagMultiple([], urlParams.get('variant_ids').split(','));
    }
  }

  render() {
    const {
      openCart, lineItemsCount, cartActive, lineItems, closeCart,
      orderNumber, guestToken, applyCouponCodeRequest,
      orderTotal, couponCodeErrorText, couponCodeSuccessText, orderLoading,
      currentModal, defaultLineItemsCount,
    } = this.props;

    return (
      <div className={styles.cart}>
        <Bucket
          ref={this.bucketRef}
          openCart={openCart}
          lineItemsCount={defaultLineItemsCount || lineItemsCount}
        />
        <CartWrapper
          applyCouponCodeRequest={applyCouponCodeRequest}
          bucketRef={this.bucketRef}
          cartActive={cartActive}
          closeCart={closeCart}
          couponCodeErrorText={couponCodeErrorText}
          couponCodeSuccessText={couponCodeSuccessText}
          currentModal={currentModal}
          guestToken={guestToken}
          lineItems={lineItems}
          lineItemsCount={lineItemsCount}
          orderLoading={orderLoading}
          orderNumber={orderNumber}
          orderTotal={orderTotal}
          defaultLineItemsCount={defaultLineItemsCount}
        />
      </div>
    );
  }
}

Cart.propTypes = {
  applyCouponCodeRequest: PropTypes.func.isRequired,
  cartActive: PropTypes.bool.isRequired,
  couponCodeErrorText: PropTypes.string,
  couponCodeSuccessText: PropTypes.string,
  closeCart: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
  guestToken: PropTypes.string,
  lineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lineItemsCount: PropTypes.number.isRequired,
  openCart: PropTypes.func.isRequired,
  orderLoading: PropTypes.bool,
  orderNumber: PropTypes.string,
  orderTotal: PropTypes.number.isRequired,
  defaultLineItemsCount: PropTypes.number,
  addToBag: PropTypes.func.isRequired,
  addToBagMultiple: PropTypes.func.isRequired,
};

Cart.defaultProps = {
  couponCodeErrorText: null,
  couponCodeSuccessText: null,
  currentModal: null,
  guestToken: null,
  orderLoading: false,
  orderNumber: null,
  defaultLineItemsCount: null,
};

const mapStateToProps = (state) => ({
  cartActive: cartActiveSelector(state),
  couponCodeErrorText: couponCodeErrorTextSelector(state),
  couponCodeSuccessText: couponCodeSuccessTextSelector(state),
  currentModal: currentModalSelector(state),
  guestToken: guestTokenSelector(state),
  lineItems: lineItemsObjectsSelector(state),
  lineItemsCount: lineItemsCountSelector(state),
  orderLoading: orderLoadingSelector(state),
  orderNumber: orderNumberSelector(state),
  orderTotal: orderTotalSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  closeCart: () => dispatch(cartActions.closeCart()),
  openCart: () => dispatch(cartActions.openCart()),
  applyCouponCodeRequest: (params) => dispatch(couponCodeActions.applyCouponCodeRequest(params)),
  addToBagMultiple: (ids, variantIds) => dispatch(
    productsActions.addToBagMultipleRequest({ ids, variantIds }),
  ),
  addToBag: (id) => dispatch(productsActions.addToBagRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
